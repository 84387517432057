@font-face {
font-family: '__englishFont_37ac50';
src: url(/_next/static/media/7f4023fd4f40f098-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__englishFont_Fallback_37ac50';src: local("Arial");ascent-override: 75.71%;descent-override: 22.38%;line-gap-override: 20.34%;size-adjust: 98.42%
}.__className_37ac50 {font-family: '__englishFont_37ac50', '__englishFont_Fallback_37ac50'
}

@font-face {
font-family: '__arabicFont_3a4536';
src: url(/_next/static/media/f34068f435a15d81-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__arabicFont_Fallback_3a4536';src: local("Arial");ascent-override: 85.29%;descent-override: 22.12%;line-gap-override: 0.00%;size-adjust: 113.50%
}.__className_3a4536 {font-family: '__arabicFont_3a4536', '__arabicFont_Fallback_3a4536'
}

@font-face {
font-family: '__sofiaFont_cf1dec';
src: url(/_next/static/media/a269576a5b66a98a-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__sofiaFont_Fallback_cf1dec';src: local("Arial");ascent-override: 72.58%;descent-override: 23.44%;line-gap-override: 32.82%;size-adjust: 104.14%
}.__className_cf1dec {font-family: '__sofiaFont_cf1dec', '__sofiaFont_Fallback_cf1dec'
}

